<template>
  <el-dialog
      :title="title"
      :visible.sync="open"
      :modal-append-to-body="true"
      :modal="false"
      top="20px"
      width="30%"
      custom-class="addClass"
      :destroy-on-close="true"
      :before-close="handleClose">

    <template #default >
      <div>
        <el-form ref="form" :inline="true" label-width="68px" :model="form" @submit.native.prevent size="small" >
          <el-form-item label="医生姓名" prop="" >
            <el-input v-model="form.search" @clear="clearFn" clearable placeholder="请输入"></el-input>
          </el-form-item>
          <el-form-item>
            <el-button type="primary" native-type="submit" @click="searchFn" >搜索</el-button>
          </el-form-item>
        </el-form>

        <el-table
            :data="table"
            v-loading="tableLoad"
            ref="tableRef"
            @selection-change="handleSelectionChange"
            border
            element-loading-text="拼命加载中"
            element-loading-spinner="el-icon-loading"
            element-loading-background="rgba(0, 0, 0, 0.8)">
          <el-table-column
              type="selection"
              align="center"
              width="55">
          </el-table-column>
          <el-table-column
              prop="docName"
              align="center"
              label="医生姓名">
          </el-table-column>
          <el-table-column
              prop="affiliatedHospital"
              align="center"
              label="所属医院">
          </el-table-column>
        </el-table>

      </div>
    </template>

    <span slot="footer" class="dialog-footer">
      <el-button size="small" @click="onCancel">取 消</el-button>
      <el-button type="primary" size="small" @click="onConfirm">确 认</el-button>
    </span>
  </el-dialog>
</template>

<script>
export default {
  name: "BindDoctor",
  components: {

  },
  props: {
    value: {
      type: Boolean,
      default: () => false,
    },
    list: {
      type: Array,
      default: () => []
    }
  },
  data() {
    return {
      title: "绑定医生",
      form: {
        search: ""
      },

      table: [],
      tableCopy: [],
      tableLoad: false,

      selectArr: [],
    }
  },
  watch: {
    open: {
      handler(newVal) {
        if (newVal) {
          this.getDocList(true);
        }
      },
      immediate: true,
    },
  },
  computed: {
    open: {
      get() {
        return this.value;
      },
      set(val) {
        this.$emit("input",val);
      }
    }
  },
  created() {

  },
  mounted() {

  },
  methods: {
    toggleRowSelection(arr) {
      if (arr.length === 0) return false;
      let ids = (arr || []).map(item => item.id),
          arr_ = (this.table || []).filter(item => ids.includes(item.id));

      (arr_ || []).forEach(item => {
        this.$refs["tableRef"].toggleRowSelection(item);
      })
    },
    handleSelectionChange(val) {
      // console.log(val);
      this.selectArr = val;
    },
    getDocList(flag = false) {
      this.$get("operate/person/doclist")
        .then(res => {
          if (!res) return false;
          let data = res.data;
          this.table = data;
          this.tableCopy = data;
          this.selectArr = [];

          if (flag) {
            this.$nextTick(() => {
              this.toggleRowSelection(this.list);
            })
          }

        })
    },
    searchFn() {
      if (this.form.search) {
        this.table = (this.tableCopy || []).filter(item => item.docName.search(this.form.search) !== -1);
        this.selectArr = [];
      }else {
        this.getDocList();
      }
    },
    clearFn() {
      this.getDocList();
    },
    onConfirm() {
      this.$emit("confirmSelect",this.selectArr);
      this.onCancel();
    },
    init() {
      this.form.search = "";
      this.selectArr = [];
    },
    onCancel() {
      this.init();
      this.open = false;
    },
    handleClose(done) {
      this.init();
      done();
    }
  }
}
</script>

<style lang="scss" scoped>

</style>
