<template>
  <div class="operationStaffClass">

    <div class="form">
      <el-form ref="form" :inline="true" label-width="100px" :model="form" @submit.native.prevent >
        <el-form-item label="运营姓名" prop="" >
          <el-input v-model="form.username" clearable placeholder="请输入"></el-input>
        </el-form-item>
        <el-form-item label="绑定医生" prop="" >
          <el-select v-model="form.docId" clearable placeholder="请选择">
            <template v-for="(item,index) in doctorList" >
              <el-option :label="item.docName" :value="item.id" :key="index" ></el-option>
            </template>
          </el-select>
        </el-form-item>
        <el-form-item>
          <el-button type="primary" @click="onSubmit" native-type="submit" >查询</el-button>
        </el-form-item>
      </el-form>
    </div>


    <div class="list">
      <div class="add">
        <el-button type="primary" @click="addFn" size="small" >新建运营人员</el-button>
      </div>
      <el-table
          :data="table"
          v-loading="tableLoad"
          element-loading-text="拼命加载中"
          element-loading-spinner="el-icon-loading"
          element-loading-background="rgba(0, 0, 0, 0.8)"
          style="width: 100%">
        <el-table-column
            prop="username"
            align="center"
            label="运营人员姓名">
          <template slot-scope="scope">
            <span class="nameClass" @click="editFn(scope.row)" >{{ scope.row.username }}</span>
          </template>
        </el-table-column>
        <el-table-column
            prop="phone"
            align="center"
            label="绑定手机">
        </el-table-column>
        <el-table-column
            prop="createTime"
            align="center"
            label="添加时间">
        </el-table-column>
        <el-table-column
            prop="createBy"
            align="center"
            label="添加人">
        </el-table-column>
        <el-table-column
            prop="docNames"
            align="center"
            label="绑定医生">
          <template #default="scope" >
            <span>{{ docName(scope.row.docNames) }}</span>
          </template>
        </el-table-column>
        <el-table-column
            prop="status"
            align="center"
            label="状态">
          <template #default="scope" >
            <span>{{ statusName(scope.row.status) }}</span>
          </template>
        </el-table-column>
        <el-table-column
            fixed="right"
            align="center"
            label="操作">
          <template slot-scope="scope">
            <template v-if="scope.row.status" >
              <el-button type="text" size="small" @click="statusFn(scope.row.userid,!scope.row.status)" >启用</el-button>
            </template>
            <template v-else >
              <el-button type="text" size="small" @click="statusFn(scope.row.userid,!scope.row.status)" >停用</el-button>
            </template>
          </template>
        </el-table-column>
      </el-table>
      <el-pagination
          @size-change="handleSizeChange"
          @current-change="handleCurrentChange"
          :current-page="form.pageNum"
          :page-sizes="[10, 20, 50, 100]"
          :page-size="form.pageSize"
          layout="total, sizes, prev, pager, next, jumper"
          :total="total">
      </el-pagination>
    </div>

    <Add v-model="AddData.show" :id="AddData.id" @updateList="getList" />

  </div>
</template>

<script>
import Add from "./components/Add";
export default {
  name: "operationStaff",
  components: {
    Add
  },
  data() {
    return {
      form: {
        pageNum: 1,
        pageSize: 10,
        username: "",
        docId: "",
      },

      table: [],
      tableLoad: false,
      total: 0,

      AddData: {
        show: false,
        id: ""
      },

      doctorList: [],
    }
  },
  computed: {
    docName() {
      return function(row) {
        let str = "",
            rowArr = row ? row.split(",") : [];

        if (rowArr.length > 0) {
          str = (this.doctorList || []).filter(item => rowArr.includes(item.id)).map(item => item.docName).join("；");
        }

        return str;
      }
    },
    statusName() {
      return function (row) {
        let str = "启用";
        if (row) str = "停用";
        return str;
      }
    }
  },
  created() {
    this.getList();
    this.getDocList();
  },
  mounted() {

  },
  methods: {
    editFn(row) {
      console.log(row);
      this.AddData.id = row.userid;
      this.AddData.show = true;
    },
    statusFn(userid,status) {
      this.$post("operate/person/forbidden",{
        userid,
        status
      })
        .then(res => {
          if (!res) return false;
          this.getList();
        })
    },
    getList() {
      this.$get("operate/person/list",this.form)
        .then(res => {
          if (!res) return false;
          let data = res.data;
          // console.log(res)
          this.table = data.list;
          this.total = data.total;
        })
    },
    onSubmit() {
      this.form.pageNum = 1;
      this.getList();
    },
    getDocList() {
      this.$get("operate/person/doclist")
          .then(res => {
            if (!res) return false;
            let data = res.data;
            this.doctorList = data;
          })
    },
    addFn() {
      this.AddData.id = "";
      this.AddData.show = true;
    },
    handleSizeChange(e) {
      this.form.pageNum = 1;
      this.form.pageSize= e;
      this.getList();
    },
    handleCurrentChange(e) {
      this.form.pageNum= e;
      this.getList();
    },
  }
}
</script>

<style lang="scss" scoped>
.nameClass{
  color: #2090ff;
  cursor: pointer;
}
</style>
