<template>
  <el-dialog
      :title="title"
      :visible.sync="open"
      :modal-append-to-body="true"
      top="100px"
      width="40%"
      custom-class="addClass"
      :destroy-on-close="true"
      :before-close="handleClose">

    <template #default >

      <el-form ref="form" label-width="110px" :model="form" :rules="rules" >
        <el-form-item label="运营人员姓名" prop="username" >
          <el-input v-model="form.username" maxlength="20" clearable placeholder="请输入"></el-input>
        </el-form-item>
        <el-form-item label="绑定手机号" prop="phone" >
          <el-input v-model="form.phone" clearable placeholder="请输入"></el-input>
        </el-form-item>
        <el-form-item label="绑定医生" prop="docIds" >
          <div class="doctor-view">

            <ul class="doctor-ul">
              <template v-for="(item,index) in form.docIds" >
                <li :key="index">
                  <p>{{ item.docName }}</p>
                  <el-button type="text" class="delClass" @click="delDoctorFn(item.id)" >删除</el-button>
                </li>
              </template>
            </ul>

            <el-button type="primary" size="small" @click="addDoctorFn" >点击添加</el-button>
          </div>
        </el-form-item>
        <el-form-item label="功能权限" prop="appletMenuIds" >
          <el-tree
              :data="TreeData"
              show-checkbox
              default-expand-all
              node-key="appletMenuId"
              ref="TreeRef"
              :props="defaultProps"
              highlight-current>
          </el-tree>
        </el-form-item>
      </el-form>

      <BindDoctor v-model="bind_doctor_data.show" @confirmSelect="doctorConfirmSelect" :list="form.docIds" />

    </template>

    <span slot="footer" class="dialog-footer">
      <el-button size="small" @click="onCancel">取 消</el-button>
      <el-button type="primary" size="small" @click="onConfirm">保存设置</el-button>
    </span>
  </el-dialog>
</template>

<script>
import BindDoctor from "@/components/operationStaff/operationStaff/components/BindDoctor";
export default {
  name: "Add",
  components: {
    BindDoctor
  },
  props: {
    value: {
      type: Boolean,
      default: () => false,
    },
    id: {
      type: String,
      default: () => ""
    },
  },
  data() {
    return {
      form: {
        userid: "",
        username: "",
        phone: "",
        docIds: [],
        appletMenuIds: [],
      },
      rules: {
        username: [
          { required: true, message: '此项为必填项', trigger: 'blur' },
          { pattern: /^[\u4e00-\u9fa5_a-zA-Z0-9]+$/, message: '姓名格式不正确' }
        ],
        phone: [
          { required: true, message: '此项为必填项', trigger: 'blur' },
          {pattern: /^((13[0-9])|(14[0,1,4-9])|(15[0-3,5-9])|(16[2,5,6,7])|(17[0-8])|(18[0-9])|(19[0-3,5-9]))\d{8}$/, message: '手机号不正确'}
        ],
        docIds: [
          { required: true, message: '此项为必填项', trigger: 'blur' },
        ],
        appletMenuIds: [
          { required: true, message: '此项为必填项', trigger: 'blur' },
        ],
      },
      TreeData: [],
      defaultProps: {
        children: 'children',
        label: 'menuName'
      },

      bind_doctor_data: {
        show: false,
      }

    }
  },
  watch: {
    open: {
      handler(newVal) {
        if (newVal) {
          this.getTree();

          if (this.id) {
            this.$nextTick(() => {
              this.form.userid = this.id;
              this.getDetails(this.form.userid);
            })
          }

        }
      },
      immediate: true,
    }
  },
  computed: {
    open: {
      get() {
        return this.value;
      },
      set(val) {
        this.$emit("input",val);
      },
    },
    title() {
      let str = "新建";
      if (this.form.userid) {
        str = "编辑";
      }
      return `${str}运营人员`;
    },
  },
  created() {

  },
  mounted() {

  },
  methods: {
    getDetails(y) {
      this.$get(`operate/person/detail/${y}`)
        .then(res => {
          if (!res) return false;
          console.log(res);
          let data = res.data;
          this.form.userid = data.userid;
          this.form.username = data.username;
          this.form.phone = data.phone;
          this.form.docIds = data.doctorInfos;
          this.$nextTick(() => {
            this.$refs["TreeRef"].setCheckedKeys((data.appletMenuInfos || []).map(item => item.appletMenuId));
          })
        })
    },
    doctorConfirmSelect(e) {
      this.form.docIds = e;
    },
    delDoctorFn(id) {
      this.form.docIds = (this.form.docIds || []).filter(item => item.id !== id );
    },
    onConfirm() {
      this.form.appletMenuIds = this.$refs["TreeRef"].getCheckedKeys();
      this.$refs["form"].validate((valid) => {
        if(valid) {
          let obj = {...this.form}
          obj.docIds = (this.form.docIds || []).map(item => item.id);
          this.sunMitApi(obj);
        }
      })
    },
    sunMitApi(e) {
      this.$post("operate/person/add",e)
        .then(res => {
          if (!res) return false;
          let data = res.data;
          console.log(data);
          this.$emit("updateList");
          this.onCancel();
        })
    },
    getTree() {
      this.$get("am/getappletmenuinfo")
          .then(res => {
            // console.log(res)
            let data = res.data;
            this.TreeData = (data.appletMenuInfosD || []).filter(item => item.appletMenuId !== "14"); // 过滤掉医生二维码
          })
    },
    onCancel() {
      this.$refs["form"].resetFields();
      this.form.userid = "";
      this.open = false;
    },
    handleClose(done) {
      this.$refs["form"].resetFields();
      this.form.userid = "";
      done();
    },
    addDoctorFn() {
      this.bind_doctor_data.show = true;
    }
  }
}
</script>

<style lang="scss" scoped>
.doctor-view{
  .doctor-ul{
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    li{
      display: flex;
      align-items: center;
      margin-bottom: 20px;
      padding: 0 10px;
      p{
        line-height: 24px;
      }
      .el-button{
        margin-left: 26px;
      }
    }
  }
}
.delClass{
  color: #f56c6c;
}
</style>
